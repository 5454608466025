import React, { Component } from 'react'

import { CUSTOMER_REVIEWS } from './Img'
import './style.scss'

export default class MobileCustomerReview extends Component {
  render() {
    return (
      <div className="customer-review layout-padding">
        <div className="customer-review-title">
          <h2>
            <img
              className="img-responsive"
              alt="รีวิว"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/black_chat_icon.png"
            />
            รีวิวจากผู้ใช้บริการ
          </h2>
        </div>
        <div className="reviews">
          {CUSTOMER_REVIEWS.map((item, index) => {
            return (
              <a
                key={index}
                href="https://lin.ee/5L0cMzc?loso=B4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="review-item">
                  <img src={item.src} alt={item.alt} />
                </div>
              </a>
            )
          })}
        </div>
      </div>
    )
  }
}
