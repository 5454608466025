import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import './style.scss'

export default class MobileHowToOrder extends Component {
  render() {
    return (
      <div className="howto-order layout">
        <h2>
          <img
            className="img-responsive"
            alt="รีวิว"
            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/chat_icon.png"
          />
          ขั้นตอนการสั่งซื้อ
        </h2>
        <div className="steps-wrapper">
          <Fade left delay={100}>
            <div className="step-item">
              <a href="https://lin.ee/5L0cMzc?loso=B8" target="_blank" rel="noopener noreferrer">
                <img
                  className="img-responsive"
                  src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/How2_01.png"
                  alt="1. แคปรูป"
                />
              </a>
              {/* <div className="divider"></div> */}
            </div>
          </Fade>
          <Fade left delay={300}>
            <div className="step-item">
              <a href="https://lin.ee/5L0cMzc?loso=B9" target="_blank" rel="noopener noreferrer">
                <img
                  className="img-responsive"
                  src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/How2_02.png"
                  alt="2. ทักไลน์ @nearwat (มี @ ด้วย)"
                />
              </a>
              {/* <div className="divider"></div> */}
            </div>
          </Fade>
          <Fade left delay={500}>
            <div className="step-item">
              <a href="https://lin.ee/5L0cMzc?loso=C1" target="_blank" rel="noopener noreferrer">
                <img
                  className="img-responsive"
                  src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/How2_03.png"
                  alt="3. แจ้งรายละเอียด"
                />
              </a>
            </div>
          </Fade>
        </div>
      </div>
    )
  }
}
