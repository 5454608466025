import React, { Component } from 'react'
import Zoom from 'react-reveal/Zoom'

import './style.scss'

export default class DesktopHeroSection extends Component {
  render() {
    const { location } = this.props
    return (
      <div className="hero-d layout-padding">
        <div className="hero-wrapper-d container">
          <div className="left-box">
            <div className="hero-title">
              <h1>
                พวงหรีดส่งด่วนถึง{' '}
                <span>
                  <img
                    className="img-responsive"
                    src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/location_icon.png"
                    alt="pin"
                  />{' '}
                  {location ? location : 'วัด/สถานที่'}
                </span>
              </h1>
            </div>
            <div className="hero-prop">
              <Zoom delay={100}>
                <div className="prop-item">
                  <p>
                    <span className="checkmark">✓</span> ถึงวัด/จุดนัดหมาย{' '}
                    <span className="highlight">ในวันนี้</span>
                  </p>
                </div>
              </Zoom>
              <Zoom delay={300}>
                <div className="prop-item">
                  <p>
                    <span className="checkmark">✓</span> ขอออกใบกำกับภาษีได้
                  </p>
                </div>
              </Zoom>
            </div>
          </div>
          <div className="right-box">
            <a href="https://lin.ee/5L0cMzc?loso=A4" target="_blank" rel="noopener noreferrer">
              <img
                className="img-responsive"
                alt="ใกล้วัดพวงหรีด ยอดขายอันดับ 1 ในปี 2020"
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/hero_img.png"
              />
            </a>
          </div>
        </div>
      </div>
    )
  }
}
