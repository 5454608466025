export const HOME_IMG_LIST = [
  // {
  //   src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_1.jpg',
  //   alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  // },
  // {
  //   src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_2.jpg',
  //   alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  // },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_3.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_4.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_5.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  },
  // {
  //   src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_6.jpg',
  //   alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  // },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_7.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  },
  // {
  //   src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_8.jpg',
  //   alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  // },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_9.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_10.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_11.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/reeds-review/reed_12.jpg',
    alt: 'สั่งพวงหรีด แอดไลน์ @nearwat'
  }
]
