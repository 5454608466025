import React, { Component } from 'react'
import Scroll from 'react-scroll'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import MobileHeroSection from 'src/components/MobileHeroSection'
import MobileTextPropSection from 'src/components/MobileTextPropSection'
import MobileExampleReed from 'src/components/MobileExampleReed'
import MobileProductCategory from 'src/components/MobileProductCategory'
import MobileCustomerReview from 'src/components/MobileCustomerReview'
import MobileHowToOrder from 'src/components/MobileHowToOrder'
import MobileReedDelivery from 'src/components/MobileReedDelivery'
import MobileFooter from 'src/components/MobileFooter'
import MobileStickyBottomButton from 'src/components/MobileStickyBottomButton'
import MobileProduct from 'src/components/MobileProduct'
import DesktopMenu from 'src/components/DesktopMenu'
import DesktopHeroSection from 'src/components/DesktopHeroSection'
import DesktopExampleReed from 'src/components/DesktopExampleReed'
import DesktopProductCategory from 'src/components/DesktopProductCategory'
import DesktopProduct from 'src/components/DesktopProduct'
import DesktopCustomerReview from 'src/components/DesktopCustomerReview'
import DesktopHowToOrder from 'src/components/DesktopHowToOrder'
import DesktopFooter from 'src/components/DesktopFooter'
import AntdCustomModal from 'src/components/AntdCustomModal'
import { setCookie, getCookie } from '../../utility/cookies'
import TagManager from 'react-gtm-module'
import { tagManagerArgs } from 'static/config/variables'


import './style.scss'

const scroller = Scroll.scroller
const Element = Scroll.Element
export default class WatPage extends Component {
  state = {
    isModalVisible: false
  }

  componentDidMount() {
    var cookies = getCookie('nearwat')
    if (cookies) {
      this.setState({ isModalVisible: false })
    } else {
      this.setState({ isModalVisible: true })
      setCookie('nearwat', 'nearwat', 365)
    }

    TagManager.initialize(tagManagerArgs)
    
    if (window.location.hash === '#howto') {
      setTimeout(() => {
        scroller.scrollTo('howto-d', {
          duration: 1500,
          delay: 100,
          smooth: false
        })
      }, 1000)

      setTimeout(() => {
        scroller.scrollTo('howto-m', {
          duration: 1500,
          delay: 100,
          smooth: false
        })
      }, 1000)
    }

    if (window.location.hash === '#review') {
      setTimeout(() => {
        scroller.scrollTo('review-d', {
          duration: 1500,
          delay: 100,
          smooth: false
        })
      }, 1000)

      setTimeout(() => {
        scroller.scrollTo('review-m', {
          duration: 1500,
          delay: 100,
          smooth: false
        })
      }, 1000)
    }

    if (window.location.hash === '#type') {
      setTimeout(() => {
        scroller.scrollTo('type-d', {
          duration: 1500,
          delay: 100,
          smooth: false
        })
      }, 1000)

      setTimeout(() => {
        scroller.scrollTo('type-m', {
          duration: 1500,
          delay: 100,
          smooth: false
        })
      }, 1000)
    }

    if (window.location.hash === '#review') {
      setTimeout(() => {
        scroller.scrollTo('review-d', {
          duration: 1500,
          delay: 100,
          smooth: false
        })
      }, 1000)

      setTimeout(() => {
        scroller.scrollTo('review-m', {
          duration: 1500,
          delay: 100,
          smooth: false
        })
      }, 1000)
    }

    if (window.location.hash === '#delivery') {
      setTimeout(() => {
        scroller.scrollTo('delivery-d', {
          duration: 1500,
          delay: 100,
          smooth: false
        })
      }, 1000)

      setTimeout(() => {
        scroller.scrollTo('delivery-m', {
          duration: 1500,
          delay: 100,
          smooth: false
        })
      }, 1000)
    }
  }

  handleOk = () => {
    this.setState({ isModalVisible: false })
  }

  render() {
    const {
      wat: {
        frontmatter: { name }
      }
    } = this.props
    return (
      <Layout>
        <SEO />
        <div className="mobile">
          <MobileHeroSection location={name} />
          {/* <MobileTextPropSection /> */}
          <Element name="type-m">
            <MobileProduct />
          </Element>
          <MobileExampleReed />
          <Element name="review-m">
            <MobileCustomerReview />
          </Element>
          <Element name="howto-m">
            <MobileHowToOrder />
          </Element>
          <Element name="delivery-m">
            <MobileReedDelivery />
          </Element>
          <MobileStickyBottomButton />
          <footer>
            <MobileFooter />
          </footer>
        </div>
        <div className="desktop">
          <DesktopMenu />
          <DesktopHeroSection location={name} />
          <Element name="type-d">
            <DesktopProduct />
          </Element>
          <DesktopExampleReed />
          <Element name="review-d">
            <DesktopCustomerReview />
          </Element>
          <Element name="howto-d">
            <DesktopHowToOrder />
          </Element>
          <footer>
            <Element name="delivery-d">
              <DesktopFooter />
            </Element>
          </footer>
        </div>
        {/* <AntdCustomModal isModalOpen={this.state.isModalVisible} onCloseModal={this.handleOk} /> */}
      </Layout>
    )
  }
}
