import React, { Component } from 'react'
import HeadShake from 'react-reveal/HeadShake'
import Zoom from 'react-reveal/Zoom'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'
import './style.scss'

export default class DesktopFooter extends Component {
  render() {
    return (
      <div className="footer-d">
        <div className="footer-wrapper container layout">
          <div className="left-box">
            <HeadShake delay={300}>
              <div className="footer-title">
                <p>พวงหรีดถึงงาน</p>
                <h3>มีรูปยืนยัน</h3>
              </div>
            </HeadShake>
            <div className="footer-button">
              <p className="footer-text">เรียกใช้บริการได้ 2 ช่องทาง</p>
              <div className="footer-button">
                <a href="https://lin.ee/5L0cMzc?loso=A3" target="_blank" rel="noopener noreferrer">
                  <div className="line-button">
                    <img
                      className="img-responsive"
                      alt="add line @nearwat"
                      src="https://d3u85r51ph21nf.cloudfront.net/premium/static/icon_line_white.png"
                    />
                    <p>LINE : @nearwat</p>
                  </div>
                </a>
                <a href={`tel:${getTelFormatWithCountryCode(TEL)}`}>
                  <div className="call-button">
                    <img
                      className="img-responsive"
                      alt={`สั่งด่วนโทร ${TEL}`}
                      src="https://d3u85r51ph21nf.cloudfront.net/premium/static/icon_telephone_white.png"
                    />
                    <p>TEL : {TEL}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="right-box">
            <Zoom delay={500}>
              <img
                className="img-responsive"
                alt="ลูกค้าไว้วางใจ เลือกใกล้วัด"
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/review_customer.png"
              />
            </Zoom>
          </div>
          <div className="footer-logo">
            <img
              className="img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/white_logo.png"
              alt="ใกล้วัด"
            />
          </div>
        </div>
      </div>
    )
  }
}
