export const getTelFormatWithCountryCode = tel => {
  return tel.replace(/^(?:0)/, '+66')
}

const host = 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/products_example/'
export const ProductExmples = {
  a: [
    { id: 'NW-E013', image: `${host}NW-E013.png`, price: 990 },
    { id: 'NW-A074', image: `${host}NW-A074.png`, price: 990 },
    { id: 'NW-E004', image: `${host}NW-E004.png`, price: 990 },
    { id: 'NW-A119', image: `${host}NW-A119.png`, price: 1390 },
    { id: 'NW-A120', image: `${host}NW-A120.png`, price: 1390 },
    { id: 'NW-A121', image: `${host}NW-A121.png`, price: 1390 }
  ],
  b: [
    { id: 'NW-B110', image: `${host}NW-B110.png`, price: 1890 },
    { id: 'NW-B109', image: `${host}NW-B109.png`, price: 1790 },
    { id: 'NW-B136', image: `${host}NW-B136.png`, price: 1690 },
    { id: 'NW-B107', image: `${host}NW-B107.png`, price: 1790 },
    { id: 'NW-B004', image: `${host}NW-B004.png`, price: 1890 },
    { id: 'NW-B135', image: `${host}NW-B135.png`, price: 1690 }
  ],
  p: [
    { id: 'NW-P041', image: `${host}NW-P041.png`, price: 3590 },
    { id: 'NW-P042', image: `${host}NW-P042.png`, price: 3590 },
    { id: 'NW-B020', image: `${host}NW-B020.png`, price: 2290 },
    { id: 'NW-P057', image: `${host}NW-P057.png`, price: 2990 }
  ],
  f: [
    { id: 'NW-F014', image: `${host}NW-F014.png`, price_16: 1690, price_18: 2190 },
    { id: 'NW-F064', image: `${host}NW-F064.png`, price_16: 1690, price_18: 2190 },
    { id: 'NW-F057', image: `${host}NW-F057.png`, price_16: 1690, price_18: 2190 },
    { id: 'NW-F058', image: `${host}NW-F058.png`, price_16: 1990, price_18: 2490 },
    { id: 'NW-F054', image: `${host}NW-F054.png`, price_16: 2290, price_18: 2790 },
    { id: 'NW-F062', image: `${host}NW-F062.png`, price_16: 1990, price_18: 2490 },
    { id: 'NW-F063', image: `${host}NW-F063.png`, price_16: 1990, price_18: 2490 },
    { id: 'NW-F065', image: `${host}NW-F065.png`, price_16: 1990, price_18: 2490 },
    { id: 'NW-F066', image: `${host}NW-F066.png`, price_16: 2290, price_18: 2790 },
    { id: 'NW-F067', image: `${host}NW-F067.png`, price_16: 1990, price_18: 2490 }
  ]
}
