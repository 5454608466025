import React, { Component } from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import './style.css'
const ModalHide = styled(Modal)`
  .ant-modal-content > .ant-modal-body {
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
`
export default class AntdCustomModal extends Component {
  render() {
    const { isModalOpen, onCloseModal } = this.props
    return (
      <Modal
        visible={isModalOpen}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        footer={null}
        centered
        closable={true}
        style={{ width: '100%', resize: 'none' }}
      >
        <img
          src={
            'https://tanjai.s3-ap-southeast-1.amazonaws.com/promotion/nearwat_promotion_03_1_2021.png'
          }
          style={{ width: '100%', resize: 'none' }}
          onClick={onCloseModal}
        />
      </Modal>
    )
  }
}
