export const CUSTOMER_REVIEWS = [
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-01.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-02.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-03.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-04.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-05.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-06.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-07.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-08.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-09.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-10.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-11.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-12.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-13.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-14.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-15.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-16.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-17.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-18.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-19.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-20.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-21.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-22.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-23.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-24.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  },
  {
    src: 'https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/reviews/chat_review_LO-25.jpg',
    alt: 'สั่งพวงหรีดด่วนแอดไลน์ @nearwat'
  }
]
