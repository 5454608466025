import React, { Component } from 'react'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'
import './style.scss'

export default class MobileFooter extends Component {
  render() {
    return (
      <div className="footer layout-padding">
        <p className="footer-text">เรียกใช้บริการได้ 2 ช่องทาง</p>
        <div className="footer-button">
          <a href="https://lin.ee/5L0cMzc?loso=B6" target="_blank" rel="noopener noreferrer">
            <div className="line-button">
              <img
                className="img-responsive"
                alt="add line @nearwat"
                src="https://d3u85r51ph21nf.cloudfront.net/premium/static/icon_line_white.png"
              />
              <p>LINE : @nearwat</p>
            </div>
          </a>
          <a href={`tel:${getTelFormatWithCountryCode(TEL)}`}>
            <div className="call-button">
              <img
                className="img-responsive"
                alt={`สั่งด่วนโทร ${TEL}`}
                src="https://d3u85r51ph21nf.cloudfront.net/premium/static/icon_telephone_white.png"
              />
              <p>TEL : {TEL}</p>
            </div>
          </a>
        </div>
        <div className="footer-logo-wrapper">
          <div className="footer-logo">
            <img
              className="img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/white_logo.png"
              alt="ใกล้วัด"
            />
          </div>
        </div>
      </div>
    )
  }
}
