import React from 'react'
import Fade from 'react-reveal/Fade'
import { ProductExmples } from '../../../static/utils'
import './style.scss'

const DesktopProduct = () => {
  const displayProduct = (product, mode) => {
    return (
      <a
        className="pg-product-item-wrapper product-view"
        href="https://lin.ee/5L0cMzc?loso=A8"
        id={product.id}
      >
        <div className="pg-product-item">
          <div className={mode === 'f' ? 'pf-product-img' : 'pg-product-img'}>
            <img className="img-responsive" src={product.image} alt={product.id} />
          </div>
          <div className="cat-line-item" />
          <div className="pg-product-detail">
            <div className="see-detail-button">
              <img src="https://i.imgur.com/ITKQGhX.png" alt="คลิก" />
              <p>แอดไลน์สั่งเลย</p>
            </div>
            <div className="pg-space-detail">
              <small>{product.id}</small>
              <p>฿{Number(product.price).toLocaleString()}</p>
            </div>
          </div>
        </div>
      </a>
    )
  }

  const displayProductFan = (product, mode) => {
    return (
      <a
        className="pg-product-item-wrapper product-view"
        href="https://lin.ee/5L0cMzc?loso=A8"
        id={product.id}
      >
        <div className="pg-product-item">
          <div className={mode === 'f' ? 'pf-product-img' : 'pg-product-img'}>
            <img className="img-responsive" src={product.image} alt={product.id} />
          </div>
          <div className="cat-line-item" />
          <div className="pg-product-detail">
            <div className="see-detail-button">
              <img src="https://i.imgur.com/ITKQGhX.png" alt="คลิก" />
              <p>แอดไลน์สั่งเลย</p>
            </div>
            <div className="pg-space-detail">
              <small>{product.id}</small>
            </div>
            <div className="pg-space-detail">
              <small>16 นิ้ว</small>
              <p>฿{Number(product.price_16).toLocaleString()}</p>
            </div>
            <div className="pg-space-detail" style={{ paddingTop: '5px' }}>
              <small>18 นิ้ว</small>
              <p>฿{Number(product.price_18).toLocaleString()}</p>
            </div>
          </div>
        </div>
      </a>
    )
  }

  return (
    <div className="product-category-d layout">
      <div className="container">
        <div className="title-section">
          <div className="title-box">
            <img
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/type_icon.png"
              alt="ประเภทพวงหรีด"
            />
            <h2>ประเภทพวงหรีด</h2>
          </div>
          <p>ท่านสามารถระบุข้อความในป้ายและเลือกได้หลายรูปแบบดังนี้...</p>
        </div>
      </div>
      {/* <div className="category-wrapper"></div> */}
      <div className="cat-container">
        <div className="cat-title-display">
          <a
            className="cat-item"
            href="https://lin.ee/5L0cMzc?loso=A8"
            target="_blank"
            rel="noopener noreferrer"
            alt="@nearwat"
          >
            <img
              className="cat-bg cat-u img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/product_category_2.png"
              alt="พวงหรีดแบบประหยัด"
            />
          </a>
        </div>
        <div className="slider-product-wrapper">
          {ProductExmples.a
            .sort((a, b) => {
              return a.price - b.price
            })
            .map(product => displayProduct(product))}
        </div>
        <p className="cat-note">
          *เนื่องจากสินค้าของเราเป็นงานฝีมือ ทำด้วยความปราณีตของบางพื้นที่อาจสต็อคของไม่เหมือนกัน
          โปรดแอดไลน์ เพื่อดูแบบตามพื้นที่จัดส่งของท่าน{' '}
          <a href="https://lin.ee/5L0cMzc?loso=B1">Add line: @nearwat</a> เพื่อสอบถาม
        </p>
      </div>
      <div className="cat-line" />
      <div className="cat-container">
        <div className="cat-title-display">
          <a
            className="cat-item"
            href="https://lin.ee/5L0cMzc?loso=A9"
            target="_blank"
            rel="noopener noreferrer"
            alt="@nearwat"
          >
            <img
              className="cat-bg cat-u img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/product_category_3.png"
              alt="พวงหรีดแบบมาตรฐาน"
            />
          </a>
        </div>

        <div className="slider-product-wrapper">
          {ProductExmples.b
            .sort((a, b) => {
              return a.price - b.price
            })
            .map(product => displayProduct(product))}
        </div>
        <p className="cat-note">
          *เนื่องจากสินค้าของเราเป็นงานฝีมือ ทำด้วยความปราณีตของบางพื้นที่อาจสต็อคของไม่เหมือนกัน
          โปรดแอดไลน์ เพื่อดูแบบตามพื้นที่จัดส่งของท่าน{' '}
          <a href="https://lin.ee/5L0cMzc?loso=B1">Add line: @nearwat</a> เพื่อสอบถาม
        </p>
      </div>
      <div className="cat-line" />
      <div className="cat-container">
        <div className="cat-title-display">
          <a
            className="cat-item"
            href="https://lin.ee/5L0cMzc?loso=B1"
            target="_blank"
            rel="noopener noreferrer"
            alt="@nearwat"
          >
            <img
              className="cat-bg cat-u img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/product_category_4.png"
              alt="พวงหรีด VIP"
            />
          </a>
        </div>
        <div className="slider-product-wrapper">
          {ProductExmples.p
            .sort((a, b) => {
              return a.price - b.price
            })
            .map(product => displayProduct(product))}
        </div>
        <p className="cat-note">
          *เนื่องจากสินค้าของเราเป็นงานฝีมือ ทำด้วยความปราณีตของบางพื้นที่อาจสต็อคของไม่เหมือนกัน
          โปรดแอดไลน์ เพื่อดูแบบตามพื้นที่จัดส่งของท่าน{' '}
          <a href="https://lin.ee/5L0cMzc?loso=B1">Add line: @nearwat</a> เพื่อสอบถาม
        </p>
      </div>
      <div className="cat-line" />
      <div className="cat-container">
        <div className="cat-title-display">
          <a
            className="cat-item"
            href="https://lin.ee/5L0cMzc?loso=B2"
            target="_blank"
            rel="noopener noreferrer"
            alt="@nearwat"
          >
            <img
              className="cat-bg cat-u img-responsive"
              src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/product_category_5.png"
              alt="พวงหรีดพัดลม"
            />
          </a>
        </div>
        <div className="slider-product-wrapper">
          {ProductExmples.f
            .sort((a, b) => {
              return a.price_16 - b.price_16
            })
            .map(product => displayProductFan(product, 'f'))}
        </div>
        <p className="cat-note">
          *เนื่องจากสินค้าของเราเป็นงานฝีมือ ทำด้วยความปราณีตของบางพื้นที่อาจสต็อคของไม่เหมือนกัน
          โปรดแอดไลน์ เพื่อดูแบบตามพื้นที่จัดส่งของท่าน{' '}
          <a href="https://lin.ee/5L0cMzc?loso=B1">Add line: @nearwat</a> เพื่อสอบถาม
        </p>
      </div>
    </div>
  )
}

export default DesktopProduct
