import React, { useState, useEffect } from 'react'
// import Fade from 'react-reveal/Fade'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'

import './style.scss'

const StickyBottomButton = () => {
  const [stlyeContact, setStlyeContact] = useState({ bottom: '0px' })
  useEffect(() => {
    if (typeof window !== 'undefined') {
      scrollAnimation()
    }
  }, [])

  const scrollAnimation = () => {
    var prevScrollpos = window.pageYOffset
    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset
      if (prevScrollpos > currentScrollPos) {
        setStlyeContact({ bottom: '0px' })
      } else {
        setStlyeContact({ bottom: '-120px' })
      }
      prevScrollpos = currentScrollPos
    }
  }

  if (true)
    return (
      // <Fade>
      <div className="bottom-button mobile" style={stlyeContact}>
        {/* Call Button */}
        <div className="action-button-wrapper">
          <div className="action-button brown">
            <img
              src="https://d3u85r51ph21nf.cloudfront.net/premium/static/icon_telephone_white.png"
              alt={TEL}
            />
            <a id="header-call-mobile" href={`tel:${getTelFormatWithCountryCode(TEL)}`} title={TEL}>
              โทรสอบถาม
            </a>
          </div>

          {/* Line Button */}
          <div className="action-button green">
            <img
              src="https://d3u85r51ph21nf.cloudfront.net/premium/static/icon_line_white.png"
              alt="@nearwat"
            />
            <a
              href="https://lin.ee/5L0cMzc?loso=C7"
              target="_blank"
              rel="noopener noreferrer"
              id="navbar-line"
              title="@nearwat"
            >
              <span>สั่งซื้อพวงหรีด</span>
            </a>
          </div>
        </div>

        <p>ตอบเร็ว มีเจ้าหน้าที่ดูแลจนกว่าพวงหรีดถึงงาน</p>
      </div>
      // </Fade>
    )
  else {
    return ''
  }
}

export default StickyBottomButton
