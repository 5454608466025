export const CATEGORY_P = 'พรีเมียม'
export const CATEGORY_B = '1,890-2,590'
export const CATEGORY_A = '1,290-1,690'
export const CATEGORY_F = '1,990-2,490'
// export const TEL = '088-552-7799'
// export const TEL = '085-357-8518'
export const TEL = '081-935-2987'
export const region = [
  {
    bangkok: 'กรุงเทพฯ',
    north: 'ภาคเหนือ',
    northeast: 'ภาคอีสาน',
    central: 'ภาคกลาง',
    east: 'ภาคตะวันออก',
    west: 'ภาคตะวันตก',
    south: 'ภาคใต้'
  }
]

export const tagManagerArgs = {
  gtmId: 'GTM-WFWTNBGG'
}

