import React, { Component } from 'react'
import Zoom from 'react-reveal/Zoom'

import './style.scss'

export default class MobileHeroSection extends Component {
  render() {
    const { location } = this.props
    return (
      <div className="hero">
        <div className="hero-logo layout">
          <img
            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/logo.png"
            alt="ใกล้วัด พวงหรีด"
          />
        </div>
        <div className="hero-title layout">
          <h1>
            พวงหรีดส่งด่วนถึง <span>{location ? location : 'วัด/สถานที่'}</span>
          </h1>
        </div>

        <div className="hero-prop">
          <div className="prop-item-box layout layout">
            <Zoom delay={100}>
              <div className="prop-item">
                <p>
                  <span className="checkmark">✓</span> ถึงวัด/จุดนัดหมาย{' '}
                  <span className="highlight">ในวันนี้</span>
                </p>
              </div>
            </Zoom>
            <Zoom delay={300}>
              <div className="prop-item">
                <p>
                  <span className="checkmark">✓</span> ขอออกใบกำกับภาษีได้
                </p>
              </div>
            </Zoom>
          </div>
          <div className="hero-img layout">
            <a href="https://lin.ee/5L0cMzc?loso=B7" target="_blank" rel="noopener noreferrer">
              <img
                className="bg img-responsive"
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/hero_img.png"
                alt="ใกล้วัดพวงหรีด"
              />
            </a>
          </div>
        </div>
      </div>
    )
  }
}
