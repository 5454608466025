import React, { Component } from 'react'

import './style.scss'

export default class MobileTextPropSection extends Component {
  render() {
    return (
      <div className="text-prop layout">
        <p>
          พวงหรีดเป็นสัญลักษณ์แห่งการไว้อาลัยและรำลึกถึงผู้จากไปถือเป็นการให้เกียรติผู้ล่วงลับในโอกาสสุดท้าย
        </p>
        <p>
          ‘ใกล้วัด’ พวงหรีดออนไลน์ ขออำนวยความสะดวกจัดทำ
          และจัดส่งพวงหรีดของท่านให้ถึงวัด/สถานที่จัดส่ง
        </p>
        {/* <div>
          <img
            className="img-responsive"
            alt="ใกล้วัด พวงหรีดออนไลน์"
            src="https://tanjai.s3-ap-southeast-1.amazonaws.com/loso/static/girl_wai_1.png"
          />
        </div> */}
      </div>
    )
  }
}
